<template>
  <v-dialog v-model="dialog" max-width="600">
    <template v-slot:activator="{on, attrs}">
      <v-btn color="primary" v-on="on" v-bind="attrs" icon>
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </template>
    <v-card elevation="0">
      <v-toolbar color="primary">
        <v-toolbar-title class="white--text">Modifier la catégorie</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="my-2">
        <v-text-field label="Nom de la catégorie" v-model="categoryName"></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-btn :disabled="editing" @click="editCategory" color="primary">Modifier</v-btn>
        <v-progress-circular indeterminate color="primary" v-if="editing"></v-progress-circular>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'EditCategory',
  props: {
    category: Object,
  },
  data() {
    return {
      categoryName: this.category.name,
      editing: false,
      dialog: false,
    }
  },
  methods: {
    async editCategory() {
      let result = await this.$store.dispatch("category/patchCategory", {id: this.category.id, name: this.categoryName});
      if(result) {
        await this.$store.dispatch("annonce/annonceSuccess", "Catégorie mis à jour.");
      }
      this.editing = false;
      this.dialog = false;
      this.$emit("reload");
    }
  }
}
</script>